import { InjectionToken, inject } from '@angular/core';
const DI_HOST_CONTROL = new InjectionToken('DI_HOST_CONTROL');
/**
 * Returns provider that can be used to provide host control. You can use it in the `providers` section
 * of your `@Component` or `@Directive`.
 *
 * ```ts {2} fileName="custom-control.component.ts"
 * @Component({
 * 	providers: [provideHostControl(CustomControlComponent)],
 * })
 * export class CustomControlComponent extends DIControl<string> {
 * 		constructor() {
 * 			super();
 * 		}
 * 	}
 * ```
 *
 * @param useExisting - The `@Component` or `@Directive` class that should be used as a host control.
 */
function provideHostControl(useExisting) {
  return {
    provide: DI_HOST_CONTROL,
    useExisting
  };
}
function injectHostControl(options) {
  return options ? inject(DI_HOST_CONTROL, options) : inject(DI_HOST_CONTROL);
}

/**
 * Generated bundle index. Do not edit.
 */

export { injectHostControl, provideHostControl };
