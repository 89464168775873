"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _chunk2UQLUWPHjs = require('./chunk.2UQLUWPH.js');
var _htmlparser2 = require('htmlparser2');
var I = {
    lowerCaseTags: !1,
    lowerCaseAttributeNames: !1,
    decodeEntities: !1
  },
  P = [{
    name: "!doctype",
    start: "<",
    end: ">"
  }],
  w = exports.parser = (g, c = {}) => {
    let a = new (0, _chunk2UQLUWPHjs.a)(g),
      i = [],
      s = [],
      p = 0,
      f = {};
    function u() {
      return i[i.length - 1];
    }
    function y(n, t) {
      return n.name instanceof RegExp ? new RegExp(n.name.source, "i").test(t) : t === n.name;
    }
    function x(n) {
      let t = {};
      return Object.keys(n).forEach(e => {
        let o = {};
        o[e] = String(n[e]).replace(/&quot;/g, '"'), c.recognizeNoValueAttribute && f[e] && (o[e] = !0), Object.assign(t, o);
      }), t;
    }
    function A(n, t) {
      var l;
      let e = P.concat((l = c.directives) != null ? l : []),
        o = u();
      for (let d of e) {
        let b = d.start + t + d.end;
        if (y(d, n.toLowerCase())) {
          if (o === void 0) {
            s.push(b);
            return;
          }
          typeof o == "object" && (o.content === void 0 && (o.content = []), Array.isArray(o.content) && o.content.push(b));
        }
      }
    }
    function N(n) {
      let t = u(),
        e = `<!--${n}-->`;
      if (t === void 0) {
        s.push(e);
        return;
      }
      typeof t == "object" && (t.content === void 0 && (t.content = []), Array.isArray(t.content) && t.content.push(e));
    }
    function m(n, t, e) {
      e === void 0 && (f[n] = !0);
    }
    function h(n, t) {
      let e = {
        tag: n
      };
      c.sourceLocations && (e.location = {
        start: a.getPosition(r.startIndex),
        end: a.getPosition(r.endIndex)
      }, p = r.endIndex), Object.keys(t).length > 0 && (e.attrs = x(t)), f = {}, i.push(e);
    }
    function T(n, t) {
      let e = i.pop();
      if (e && typeof e == "object" && e.location && r.endIndex !== null && (t ? p < r.startIndex && (e.location.end = a.getPosition(r.startIndex - 1)) : e.location.end = a.getPosition(r.endIndex)), e) {
        let o = u();
        if (i.length <= 0) {
          s.push(e);
          return;
        }
        typeof o == "object" && (o.content === void 0 && (o.content = []), Array.isArray(o.content) && o.content.push(e));
      }
    }
    function v(n) {
      let t = u();
      if (t === void 0) {
        s.push(n);
        return;
      }
      if (typeof t == "object") {
        if (t.content && Array.isArray(t.content) && t.content.length > 0) {
          let e = t.content[t.content.length - 1];
          if (typeof e == "string" && !e.startsWith("<!--")) {
            t.content[t.content.length - 1] = `${e}${n}`;
            return;
          }
        }
        t.content === void 0 && (t.content = []), Array.isArray(t.content) && t.content.push(n);
      }
    }
    let r = new (0, _htmlparser2.Parser)({
      onprocessinginstruction: A,
      oncomment: N,
      onattribute: m,
      onopentag: h,
      onclosetag: T,
      ontext: v
    }, {
      ...I,
      ...c
    });
    return r.write(g), r.end(), s;
  };
exports.parser = w;