import { DI_DEFAULT_COMPARE } from 'di-controls/constants';
class SetCompare {
  values = new Set();
  compareFn;
  constructor(compareFn, newValues) {
    this.compareFn = compareFn || DI_DEFAULT_COMPARE;
    this.values = new Set(newValues || []);
  }
  get size() {
    return this.values.size;
  }
  *[Symbol.iterator]() {
    for (const value of this.values) {
      yield value;
    }
  }
  add(value) {
    const item = this.find(value);
    !item && this.values.add(value);
  }
  delete(value) {
    const item = this.find(value);
    item && this.values.delete(item);
  }
  has(value) {
    return this.values.has(value) || !!this.find(value);
  }
  clear() {
    this.values = new Set();
  }
  toArray() {
    return Array.from(this.values);
  }
  find(value) {
    /* trying to find element as is, because its the fastest way */
    if (this.values.has(value)) {
      return value;
    }
    /* if we didn't find it then trying to use compare function to do that */
    for (const v of this.values) {
      if (this.compareFn(value, v)) {
        return v;
      }
    }
    return null;
  }
}

/** Uses to implement host with compare function */
class DICompareHost {}
function provideCompareHost(useExisting) {
  return {
    provide: DICompareHost,
    useExisting
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { DICompareHost, SetCompare, provideCompareHost };
