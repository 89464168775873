function isPresent(value) {
  return value !== undefined && value !== null && (typeof value !== 'string' || value !== '');
}
function hasValue(value) {
  return isPresent(value) && (Array.isArray(value) && !!value.length || typeof value === 'string' && !!value.length || !['string'].includes(typeof value) && !Array.isArray(value));
}

/**
 * Generated bundle index. Do not edit.
 */

export { hasValue, isPresent };
