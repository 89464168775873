"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var I = Object.defineProperty,
  U = Object.defineProperties;
var _ = Object.getOwnPropertyDescriptors;
var N = Object.getOwnPropertySymbols;
var j = Object.prototype.hasOwnProperty,
  v = Object.prototype.propertyIsEnumerable;
var g = (o, r, n) => r in o ? I(o, r, {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: n
  }) : o[r] = n,
  b = (o, r) => {
    for (var n in r || (r = {})) j.call(r, n) && g(o, n, r[n]);
    if (N) for (var n of N(r)) v.call(r, n) && g(o, n, r[n]);
    return o;
  },
  R = (o, r) => U(o, _(r));
var _isjson = require('is-json');
var _isjson2 = _interopRequireDefault(_isjson);
var $;
(function (s) {
  s[s.Smart = 0] = "Smart", s[s.Single = 1] = "Single", s[s.Double = 2] = "Double";
})($ || ($ = exports.quoteStyleEnum = {}));
var d;
(function (c) {
  c.tag = "tag", c.slash = "slash", c.default = "default", c.closeAs = "closeAs";
})(d || (d = exports.closingSingleTagOptionEnum = {}));
var i;
(function (s) {
  s.tag = "tag", s.slash = "slash", s.default = "default";
})(i || (i = exports.closingSingleTagTypeEnum = {}));
var y = ["area", "base", "br", "col", "command", "embed", "hr", "img", "input", "keygen", "link", "menuitem", "meta", "param", "source", "track", "wbr"],
  L = /[\t\n\f\r "'`=<>]/,
  B = {
    closingSingleTag: void 0,
    quoteAllAttributes: !0,
    replaceQuote: !0,
    quoteStyle: 2
  };
function q(o, r = {}) {
  var x;
  let n = y;
  r.singleTags && (n = [...new Set([...y, ...r.singleTags])]), r = R(b(b({}, B), r), {
    singleTags: n
  });
  let {
      singleTags: s,
      closingSingleTag: c,
      quoteAllAttributes: k,
      replaceQuote: Q,
      quoteStyle: p
    } = r,
    h = (x = s == null ? void 0 : s.filter(a => a instanceof RegExp)) != null ? x : [];
  return Array.isArray(o) || (o || (o = ""), o = [o]), u(o);
  function u(a) {
    let t = "";
    for (let e of a) {
      if (e === !1 || e === void 0 || e === null || typeof e == "string" && e.length === 0 || Number.isNaN(e)) continue;
      if (Array.isArray(e)) {
        if (e.length === 0) continue;
        t += u(e);
        continue;
      }
      if (typeof e == "string" || typeof e == "number") {
        t += e;
        continue;
      }
      if (Array.isArray(e.content) || (e.content || (e.content = ""), e.content = [e.content]), e.tag === !1) {
        t += u(e.content);
        continue;
      }
      let f = typeof e.tag == "string" ? e.tag : "div";
      t += `<${f}`, e.attrs && (t += w(e.attrs));
      let l = {
        [i.tag]: `></${f}>`,
        [i.slash]: " />",
        [i.default]: ">"
      };
      if (m(f)) {
        switch (c) {
          case d.tag:
            t += l[i.tag];
            break;
          case d.slash:
            t += l[i.slash];
            break;
          case d.closeAs:
            t += l[e.closeAs ? i[e.closeAs] : i.default];
            break;
          default:
            t += l[i.default];
        }
        e.content && (t += u(e.content));
      } else if (c === d.closeAs && e.closeAs) {
        let D = e.closeAs ? i[e.closeAs] : i.default;
        t += `${l[D]}${u(e.content)}`;
      } else t += `>${u(e.content)}</${f}>`;
    }
    return t;
  }
  function m(a) {
    return h.length > 0 ? h.some(t => t.test(a)) : !!(s == null ? void 0 : s.includes(a.toLowerCase()));
  }
  function w(a) {
    let t = "";
    for (let [e, f] of Object.entries(a)) if (typeof f == "string") {
      if (_isjson2.default.call(void 0, f)) t += A(e, f);else if (k || L.test(f)) {
        let l = f;
        Q && (l = f.replace(/"/g, "&quot;")), t += A(e, l, p);
      } else f === "" ? t += ` ${e}` : t += ` ${e}=${f}`;
    } else f === !0 ? t += ` ${e}` : typeof f == "number" && (t += A(e, f, p));
    return t;
  }
  function A(a, t, e = 1) {
    return e === 1 ? ` ${a}='${t}'` : e === 2 ? ` ${a}="${t}"` : typeof t == "string" && t.includes('"') ? ` ${a}='${t}'` : ` ${a}="${t}"`;
  }
}
exports.closingSingleTagOptionEnum = d;
exports.closingSingleTagTypeEnum = i;
exports.quoteStyleEnum = $;
exports.render = q;