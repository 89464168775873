"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var i = class {
  constructor(t) {
    this.source = t, this.lastPosition = {
      line: 1,
      column: 1
    }, this.lastIndex = 0;
  }
  getPosition(t) {
    if (t < this.lastIndex) throw new Error("Source indices must be monotonic");
    for (; this.lastIndex < t;) this.source.charCodeAt(this.lastIndex) === 10 ? (this.lastPosition.line++, this.lastPosition.column = 1) : this.lastPosition.column++, this.lastIndex++;
    return {
      line: this.lastPosition.line,
      column: this.lastPosition.column
    };
  }
};
exports.a = i;